import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { BasketIcon } from 'src/components/UI/Icon/Icon'


import styles from './CartButton.module.scss'

type Props = {
  cart?: any
  ui?: any
}

@inject('cart', 'ui')
@observer
class CartButton extends React.Component<Props> {
  handleClick = () => {
    const { ui } = this.props
    ui.setActiveMenu(ui.activeMenu === 'cart' ? '' : 'cart')
  }

  render() {
    const { cart } = this.props

    return (
      <button
        onClick={this.handleClick}
        className={styles.cartButton}
      >
        <div>
          <BasketIcon width={24} height={24} color="black" />
        </div>
        <div className={styles.quantity}>
          {cart && (
            cart.count
          )}
        </div>
      </button>
    )
  }
}

export default CartButton
