import * as React from 'react'
import Wrapper from 'src/components/Wrapper'
import Layout from 'src/components/Layout'

import 'src/styles/global.scss'

export const wrapRootElement = Wrapper

export const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
)

const supportsWebp = async () => {
  if (!window.createImageBitmap) return false

  const webpData =
    'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA='
  const blob = await fetch(webpData).then(r => r.blob())

  return createImageBitmap(blob).then(() => true, () => false)
}


export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }
  if (await supportsWebp()) {
    if (document) {
      document.body.classList.add('webp-checked')
      document.body.classList.add('support-webp')
    }
  } else {
    document.body.classList.add('webp-checked')
  }
}
