const path = require('path')

// Locales represent contenful-locales.
// Keys need to be set up in contenful with correct spelling.

// contenfulModels represent contentful models. Keys is the id of the specific model
// Set json to write data to a json file. Set component to create a page with the data as pageContext

module.exports = {
  locales: {
    'en-US': {
      path: '/en',
      title: 'English',
    },
  },
  siteName: 'House of bradford',
  globalSiteName: 'Global',
  contentfulModels: {
    startpage: {
      json: 'src/json/startpages.json',
    },
    siteConfig: {
      json: 'src/json/siteconfigs.json',
    },
    menu: {
      json: 'src/json/menus.json',
    },
    informationPage: {
      component: path.resolve('./src/templates/information.tsx'),
    },
    faqPage: {
      component: path.resolve('./src/templates/faq.tsx'),
    },
    productInfo: {
      json: 'src/json/productinfo.json',
    },
  },
  contentful: {
    space: 'pnzuba3vwxzh',
    accessToken: '-3HT_g4olmDOyvDa7WsH8UrYetRJq9nkswTRVwI22qc',
  },
  centra: {
    url: 'https://weareacos.centra.com/api/house-of-bradford/',
    auth: 'VxaEMkoZkvEappt6P6txHoqp+0tlo+5a',
  },
  checkout: {
    apiUrl: 'https://weareacos.centra.com/api/checkout/',
    locale: 'en',
  },
}
