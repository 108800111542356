import * as React from 'react'
import { inject, observer } from 'mobx-react'

import { CloseIcon } from 'src/components/UI/Icon/Icon'

import CartItem from './CartItem'
import CartSummary from './CartSummary'
import styles from './Cart.module.scss'

type Props = {
  cart?: any
  ui?: any
  locale: string
}

@inject('cart', 'ui')
@observer
class Cart extends React.Component<Props> {
  contentRef: React.RefObject<HTMLDivElement>
  wrapperRef: React.RefObject<HTMLDivElement>

  constructor(props: Props) {
    super(props)
    this.wrapperRef = React.createRef()
    this.contentRef = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event: any) => {
    if (
      this.wrapperRef &&
      this.wrapperRef.current &&
      this.wrapperRef.current.contains(event.target) &&
      this.contentRef &&
      this.contentRef.current &&
      !this.contentRef.current.contains(event.target)
    ) {
      this.closeMenu()
    }
  }

  closeMenu = () => {
    const { ui } = this.props
    ui.setActiveMenu('')
  }

  render() {
    const { cart, ui, locale } = this.props
    const active = ui.activeMenu === 'cart'
    const items = cart.items
    const overlayClass = active
      ? [styles.cartOverlay, styles.cartOverlayActive].join(' ')
      : styles.cartOverlay

    return (
      <div ref={this.wrapperRef} className={overlayClass}>
        <div ref={this.contentRef} className={styles.cartMenu}>
          <div className={styles.cartHeader}>
            <p>
              {'My bag'}
            </p>
            <div onClick={this.closeMenu} className={styles.closeButton}>
              <CloseIcon width={20} height={20} color="black" />
            </div>
          </div>
          {items && items.length > 0 ? (
            <React.Fragment>
              <div className={styles.cartItems}>
                <div>
                  {items.map((item: any, index: number) => (
                    <CartItem
                      handleClick={this.closeMenu}
                      cart={cart}
                      item={item}
                      key={index}
                      locale={locale}
                    />
                  ))}
                </div>
              </div>
              <CartSummary handleClick={this.closeMenu} />
            </React.Fragment>
          ) : (
              <div className={styles.emptyCart}>{'Your bag is empty'}</div>
            )}
        </div>
      </div>
    )
  }
}

export default Cart
