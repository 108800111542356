import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { t as typy } from 'typy'

import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'
import PrimaryButton from 'src/components/UI/Button/PrimaryButton'
import { SpinnerIcon } from 'src/components/UI/Icon/Icon'

import styles from './CartSummary.module.scss'

type Props = {
  cart?: any
  handleClick: any
}

@inject('cart')
@observer
class CartSummary extends React.Component<Props> {
  render() {
    const { cart, handleClick } = this.props
    const itemTotal = typy(cart, 'cart.selection.totals.itemsTotalPrice')
      .safeObject

    return (
      <div className={styles.wrapper}>
        <div className={styles.totals}>
          <div className={styles.totalsRow}>
            <p>{'Total'}</p>
            <p>{itemTotal}</p>
          </div>
        </div>
        <LinkComponent
          onClick={handleClick}
          to={'/checkout'}
          className={styles.button}
        >
          <PrimaryButton disabled={cart.loading}>
            {cart.loading ? (
              <SpinnerIcon width={24} height={24} color="white" />
            ) : (
                'Checkout'
              )}
          </PrimaryButton>
        </LinkComponent>
      </div>
    )
  }
}

export default CartSummary
