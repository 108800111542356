import * as React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

import styles from './RichText.module.scss'

type Props = {
  content: any
}

function renderMedia(file: any) {
  if (file.contentType === 'image/jpeg') {
    return <img className="img-fluid" src={file.url} />
  }
  return null
}

const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      const file = node.data.target.fields ? node.data.target.fields.file : null
      return renderMedia(file)
    },
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className={styles.row}>{children}</p>
    ),
  },
}

const RichText = ({ content }: Props) => {
  return (
    <div className={styles.richTextWrapper}>
      {documentToReactComponents(content, options)}
    </div>
  )
}

export default RichText
