import * as React from 'react'
import classnames from 'classnames'
import isNode from 'is-node'
import images from 'src/json/images.json'
import isEqual from 'lodash.isequal'

const folder = '/images'

type Breakpoints = {
  mobile: string
  tablet: string
  desktop: string
  [key: string]: string
}

interface Props {
  src?: string
  srcSet?: {
    [key: string]: string
  }
  alt: string
  className?: any
  sizes?: string | Breakpoints
  critical?: boolean
  ratio?: number
  progressive?: boolean
  lowRes?: string
}

export const breakpoints: Breakpoints = {
  mobile: '(max-width: 767px)',
  tablet: '(min-width: 768px) and (max-width: 1023px)',
  desktop: '(min-width: 1024px)',
}

const noHeightStyles = {
  opacity: 0,
  height: 0,
  margin: 0,
  borderTopWidth: 0,
  borderBottomWidth: 0,
  paddingTop: 0,
  paddingBottom: 0,
  minHeight: 0,
}

function setWidthHeight(node: any) {
  if (!isNode && node && 'getComputedStyle' in window) {
    const compStyles = window.getComputedStyle(node)
    const width = compStyles.getPropertyValue('width')
    const height = compStyles.getPropertyValue('height')
    node.style.height = height
    node.style.width = width
  }
}

export default class Image extends React.Component<Props> {
  preloadRef: any
  imageRef: any
  observer: any = null
  visible = false

  static defaultProps = {
    sizes: '100vw',
    progressive: true,
  }

  constructor(props: Props) {
    super(props)
    if (!props.src && !props.srcSet) {
      throw new Error('You must specify src or srcSet')
    }
    if (typeof IntersectionObserver !== 'undefined') {
      this.observer = new IntersectionObserver(this.observeHandler, {
        rootMargin: '0px 0px 300px 0px',
      })
    }
  }

  componentDidMount() {
    const { critical } = this.props
    if (critical) {
      this.show()
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { src, srcSet, critical, progressive } = this.props
    if (!isEqual(prevProps.src, src) || !isEqual(prevProps.srcSet, srcSet)) {
      if (critical) {
        this.loadNextImage(this.imageRef)
      } else {
        this.loadNextImage(this.preloadRef)
      }
    }
  }

  loadNextImage(ref: any) {
    const { progressive } = this.props
    if (!ref) {
      return
    }
    if (progressive) {
      ref.onload = () => {
        if (ref) {
          ref.onload = null
          this.setSrc(ref)
        }
      }
      ref.removeAttribute('srcSet')
      ref.setAttribute('src', this.lowRes)
    } else {
      this.setSrc(ref)
    }
  }

  observeHandler = (entries: any) => {
    for (const entry of entries) {
      if (entry.isIntersecting && this.imageRef && this.observer) {
        this.show()
        this.observer.unobserve(entry.target)
      }
    }
  }

  getClosestSrc(width: number) {
    const { srcSet, src } = this.props
    if (!srcSet) {
      return src || ''
    }
    const closest = Object.keys(srcSet)
      .map((size: string) => parseFloat(size))
      .reduce((prev: number, curr: number) =>
        Math.abs(curr - width) < Math.abs(prev - width) ? curr : prev
      )
    return srcSet[closest.toString()]
  }

  getImage = () => {
    const { src } = this.props
    return images.find((image: any) => {
      return image.url === src
    })
  }

  get srcSet() {
    const { srcSet, ratio } = this.props
    if (!srcSet) {
      return ''
    }
    const r = ratio || 1
    return Object.keys(srcSet)
      .map(
        (width: string) =>
          // @ts-ignore
          `${srcSet[width]} ${Math.round(parseFloat(width) / r)}w`
      )
      .join(', ')
  }

  get src(): string {
    const { src } = this.props
    if (src) {
      return src
    }
    return this.getClosestSrc(800)
  }

  get lowRes(): string {
    const { lowRes } = this.props
    return lowRes || this.getClosestSrc(400)
  }

  get placeholder(): string {
    const { ratio } = this.props
    if (!ratio) {
      // Return an empty pixel
      return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP89fkzEwAIswLkYLzKuwAAAABJRU5ErkJggg=='
    }
    const viewBox = ratio > 1 ? `0 0 1 ${ratio}` : `0 0 ${ratio} 1`
    return `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="${viewBox}"><rect fill="${encodeURIComponent(
      '#FFFFFF00'
    )}" width="100%" height="100%" /></svg>`
  }

  get sizes(): string {
    const { sizes } = this.props
    if (typeof sizes === 'string') {
      return sizes
    } else if (typeof sizes === 'object') {
      return Object.keys(sizes)
        .map((width: string) => `${breakpoints[width]} ${sizes[width]}`)
        .join(',')
    }
    return Image.defaultProps.sizes
  }

  supportsWebp = () => {
    if (!isNode && document) {
      return document.body.classList.value.includes('support-webp')
    } else {
      return false
    }
  }

  getSrc = () => {
    const isWebp = this.supportsWebp()
    const image = this.getImage()
    const { files, type } = image

    if (type === 'svg') {
      return `${folder}${files[0].file}`
    }

    let file = ''
    if (files.length > 2) {
      file = `${folder}${files[2].file}`
    } else {
      file = `${folder}${files[files.length - 1].file}`
    }
    return isWebp ? file.replace(`.${type}`, '.webp') : file
  }

  getSrcSet = () => {
    const image = this.getImage()
    const isWebp = this.supportsWebp()
    const { files, type } = image
    if (type === 'svg') {
      return ''
    }
    const srcString = files.map((file: any) => {
      if (isWebp) {
        return `${folder}${file.file.replace(`.${type}`, '.webp')} ${file.size}w`
      }
      return `${folder}${file.file} ${file.size}w`
    })
    return srcString.join(', ')
  }

  setSrc(node: any) {
    if (node) {
      node.setAttribute('src', this.getSrc())
      node.setAttribute('srcset', this.getSrcSet())
    }
  }

  show() {
    if (this.visible) {
      return
    }
    this.visible = true
    const { critical, progressive } = this.props
    if (this.preloadRef && this.imageRef) {
      if (this.src && !this.src.endsWith('.svg')) {
        setWidthHeight(this.imageRef)
      }
      if (critical && progressive) {
        this.imageRef.onload = () => {
          if (this.imageRef) {
            if (critical) {
              this.setSrc(this.imageRef)
            }
            this.imageRef.onload = null
            this.imageRef.style.height = null
            this.imageRef.style.width = null
          }
        }
        this.imageRef.setAttribute('src', this.lowRes)
        if (this.preloadRef) {
          this.preloadRef.style.display = 'none'
          this.preloadRef.style.height = null
          this.preloadRef.style.width = null
        }
      } else {
        this.preloadRef.onload = () => {
          if (this.preloadRef && this.imageRef) {
            this.preloadRef.onload = null
            Object.keys(noHeightStyles).forEach((prop: string) => {
              this.preloadRef.style[prop] = null
            })
            if (progressive) {
              if (this.src && !this.src.endsWith('.svg')) {
                setWidthHeight(this.imageRef)
              }
              this.preloadRef.onload = () => {
                if (this.preloadRef) {
                  this.preloadRef.onload = null
                  this.preloadRef.style.height = null
                  this.preloadRef.style.width = null
                }
              }
              this.preloadRef.setAttribute('srcSet', this.getSrcSet())
            }
            this.imageRef.style.display = 'none'
            this.imageRef.style.height = null
            this.imageRef.style.width = null
          }
        }
        if (progressive && this.preloadRef) {
          this.preloadRef.setAttribute('src', this.getSrc())
        } else {
          this.setSrc(this.preloadRef)
        }
      }
    }
  }

  render() {
    let { alt, critical, className, ratio } = this.props
    let ainoClass = 'aino-image'
    if (this.src && this.src.endsWith('.svg')) {
      ainoClass = ''
    }

    className = classnames(className, ainoClass, { critical })
    return (
      <React.Fragment>
        {isNode ? (
          <noscript>
            <img className={className} src={this.src} alt={alt} />
          </noscript>
        ) : null}
        <img
          ref={x => {
            this.imageRef = x
            if (x && this.observer) {
              this.observer.observe(x)
            }
          }}
          className={classnames(className, 'no-js')}
          sizes={this.sizes}
          src={this.placeholder}
          srcSet=""
          alt={alt}
          data-lowres={critical && isNode ? this.lowRes : ''}
        />
        {!isNode ? (
          <img
            ref={x => (this.preloadRef = x)}
            className={className}
            sizes={this.sizes}
            style={noHeightStyles}
            alt={alt}
          />
        ) : null}
      </React.Fragment>
    )
  }
}
