import * as React from 'react'
import isNode from 'is-node'
import { inject, observer } from 'mobx-react'
import { autorun } from 'mobx'
import { autobind } from 'core-decorators'
import { Helmet } from 'react-helmet'

import Preview from 'src/stores/preview'
import Ui from 'src/stores/ui'
import configs from 'src/json/siteconfigs.json'
import menus from 'src/json/menus.json'
import { getCurrentSiteData } from 'src/helpers/getCurrentSiteData'
import { getSeoData } from 'src/helpers/getSeoData'

import SEO from './SEO/SEO'

type Props = {
  content: any
  ui?: Ui
  preview?: Preview
  location?: any
}

@inject('preview', 'ui')
@observer
export default class App extends React.Component<Props> {
  disposer: any

  constructor(props: Props) {
    super(props)
    const { ui } = props

    this.disposer = autorun(() => {
      if (!isNode) {
        if (ui && ui.activeMenu) {
          document.body.classList.add('prevent-overflow')
        } else {
          document.body.classList.remove('prevent-overflow')
        }
      }
    })
  }

  componentDidMount(): void {
    const { ui, preview, location } = this.props
    ui!.setWindowSize({
      height: window.innerHeight,
      width: window.innerWidth,
    })
    if (preview) {
      preview.checkUser(location)
    }
    window.addEventListener('resize', this.onResize)
  }

  @autobind
  onResize(): void {
    const { ui } = this.props
    ui!.setWindowSize({
      height: window.innerHeight,
      width: window.innerWidth,
    })
  }

  render(): JSX.Element {
    let config = getCurrentSiteData(configs)
    let menu = getCurrentSiteData(menus)
    const { content, preview } = this.props

    if (preview && preview.previewData) {
      const previewConfig = preview.getCurrentPageContext(
        config.contentType,
        config.locale
      )
      const previewMenu = preview.getCurrentPageContext(
        menu.contentType,
        menu.locale
      )

      if (previewConfig) {
        config = previewConfig
      }
      if (previewMenu) {
        menu = previewMenu
      }
    }

    const { seoData } = config

    const { seoMetaDescription, seoTitle, seoOgimage } = getSeoData(seoData)

    return (
      <div className="app-wrapper">
        <Helmet titleTemplate={`%s | ${seoTitle}`} defaultTitle={seoTitle} />
        <SEO
          type="website"
          seoOgimage={seoOgimage}
          seoMetaDescription={seoMetaDescription}
        />
        {content}
      </div>
    )
  }
}
