import * as React from 'react'
import { Location } from '@reach/router'
import { Provider } from 'mobx-react'
import { configure } from 'mobx'

import App from 'src/components/App'
import Cookie from 'src/stores/cookie'
import Preview from 'src/stores/preview'
import Ui from 'src/stores/ui'
import Cart from 'src/stores/cart'

const stores = {
  cookie: new Cookie(),
  preview: new Preview(),
  ui: new Ui(),
  cart: new Cart(),
}

configure({
  enforceActions: 'always', // enforces @action to update mobx states
})

const Wrapper = ({ element }: { element: any }): JSX.Element => (
  <Provider {...stores}>
    <Location>
      {({ location }) => <App location={location} content={element} />}
    </Location>
  </Provider>
)

export default Wrapper
