import * as React from 'react'

import styles from './CartItem.module.scss'

type Props = {
  productId: string
}

const ItemMissing = ({ productId }: Props) => (
  <div className={styles.cartItem}>
    <div className={styles.image}></div>
    <div className={styles.info}>This item is no longer available</div>
    <div className={styles.right}></div>
  </div>
)

export default ItemMissing
