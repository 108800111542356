import { observable, action } from 'mobx'
import localForage from 'localforage'
import isNode from 'is-node'
import md5 from 'md5'

import config from 'src/config'
import { parseProducts, fetchCentraData } from 'src/helpers/centraHelpers'
import {
  fetchContentful,
  getContentTypeArray,
} from 'src/helpers/contentfulHelpers'
import { storageKeys } from 'src/constants'
import projectuser from 'src/json/projectuser.json'

export default class Preview {
  @observable previewActive: boolean = false
  @observable previewData: null | any[] = null
  @observable previewProducts: null | any[] = null
  @observable isLoggedIn: boolean = false

  timer: null | any = null

  constructor() {
    if (!isNode) {
      localForage.getItem(storageKeys.PREVIEW).then(async (preview: any) => {
        if (preview) {
          this.setLoggedInUser()
        }
      })
    }
  }

  @action fetchData = async () => {
    const entries = await fetchContentful(config)
    const productData = await fetchCentraData(config, 'products')

    const products = parseProducts(productData)

    let data: any[] = []
    Object.keys(config.contentfulModels).forEach(key => {
      const contentTypeArray = getContentTypeArray(entries, key)
      data = [...data, ...contentTypeArray]
    })
    this.fetchDataSuccess(data, products)
    this.timer = setTimeout(this.fetchData, 6000)
  }

  @action.bound
  fetchDataSuccess(data: any, products: any) {
    this.previewData = data
    this.previewProducts = products
  }

  @action.bound
  setPreviewStatus(status: boolean) {
    this.previewActive = status
    if (status) {
      this.fetchData()
    } else {
      this.previewData = null
      this.previewProducts = null
      clearTimeout(this.timer)
    }
  }

  getCurrentPageContext(contentType: string, locale: string, slug?: string) {
    if (!this.previewData) {
      return null
    }
    let data = null
    if (slug) {
      data = this.previewData.filter(page => {
        return (
          page.contentType === contentType &&
          page.locale === locale &&
          page.slug === slug
        )
      })
    } else {
      data = this.previewData.filter(page => {
        return page.contentType === contentType && page.locale === locale
      })
    }
    if (data) {
      if (data.length > 1) {
        const sitePages = data.filter(page => page.site === config.siteName)
        if (sitePages.length > 0) {
          return sitePages[0]
        } else {
          return data[0]
        }
      } else {
        return data[0]
      }
    }
    return null
  }

  getCurrentProductContext(product: string) {
    if (!this.previewProducts) {
      return null
    }
    const response = this.previewProducts.filter(item => {
      return item.product === product
    })
    return response[0]
  }

  @action.bound
  logoutUser() {
    localForage.removeItem(storageKeys.PREVIEW)
    this.isLoggedIn = false
    this.setPreviewStatus(false)
  }

  @action.bound
  setLoggedInUser() {
    localForage.setItem(storageKeys.PREVIEW, JSON.stringify(true))
    this.isLoggedIn = true
  }

  checkUser(location: any) {
    if (!this.isLoggedIn) {
      const url = new URL(location.href)
      const preview = url.searchParams.get('preview')
      if (projectuser && preview !== null) {
        const password = prompt('Please enter password for preview mode', '')
        if (password) {
          if (md5(password) === projectuser.password) {
            this.setLoggedInUser()
          } else {
            alert(
              'Sorry, wrong password. Reload page if you want to try again.'
            )
          }
        }
      }
    }
  }
}
