import * as React from 'react'
import { Link } from 'gatsby'

import { getSlugByLocale } from 'src/helpers/getSlugByLocale'

type Props = {
  to: string
  locale: string
  children: any
  onClick?: any
  className?: any
}

const LinkComponent = ({ to, locale, children, onClick, className }: Props) => {
  const isExternal = to.substr(0, 4) === 'http'

  return (
    isExternal ? (
      <a href={to} onClick={onClick} className={className}>
        {children}
      </a>
    ) : (
      <Link
      onClick={onClick}
      to={getSlugByLocale(to, locale)}
      className={className}
    >
      {children}
    </Link>
    )
  )
}

export default LinkComponent
