import axios from 'axios'

const config = require('./../config')

const API_BASE = config.checkout.apiUrl

export const createCart = async () => {
  try {
    console.log(`${API_BASE}selection`)

    const response = await axios.get(`${API_BASE}selection`)
    return response.data
  } catch (e) {
    throw Error(e)
  }
}

export const fetchCentraProduct = async (
  token: string,
  productId: string | number
) => {
  let response = { data: null }
  try {
    response = await axios({
      url: `${API_BASE}products/${productId}`,
      headers: {
        'API-Token': token,
      },
    })
  } catch (e) {}
  return response.data
}

export const refreshCart = async (token: string) => {
  try {
    const response = await axios({
      url: `${API_BASE}/selection`,
      method: 'GET',
      headers: {
        'API-Token': token,
      },
    })
    return response.data
  } catch (e) {
    throw Error(e)
  }
}

export const addItem = async (token: string, item: string) => {
  try {
    const response = await axios({
      url: `${API_BASE}items/${item}`,
      method: 'POST',
      headers: {
        'API-Token': token,
      },
    })
    return response.data
  } catch (e) {
    throw Error(e)
  }
}

export const updateItemQuantity = async (
  token: string,
  line: string,
  quantity: string
) => {
  try {
    const response = await axios({
      url: `${API_BASE}lines/${line}/quantity/${quantity}`,
      method: 'PUT',
      headers: {
        'API-Token': token,
      },
    })
    return response.data
  } catch (e) {
    throw Error(e)
  }
}

export const removeItem = async (token: string, line: string) => {
  try {
    const response = await axios({
      url: `${API_BASE}lines/${line}`,
      method: 'DELETE',
      headers: {
        'API-Token': token,
      },
    })
    return response.data
  } catch (e) {
    throw Error(e)
  }
}

export const changeCountry = async (token: string, country: string) => {
  try {
    const response = await axios({
      url: `${API_BASE}countries/${country}`,
      method: 'PUT',
      headers: {
        'API-Token': token,
      },
    })
    return response.data
  } catch (e) {
    throw Error(e)
  }
}

export const loginCentraUser = async (
  token: string,
  email: string,
  password: string
) => {
  try {
    const response = await axios({
      url: `${API_BASE}login/${email}`,
      method: 'POST',
      headers: {
        'API-Token': token,
      },
      data: {
        password: password,
      },
    })
    return response.data
  } catch (e) {
    return false
  }
}

export const signupNewsletter = async (email: string) => {
  try {
    const response = await axios({
      url: `${API_BASE}newsletter-subscription/${email}`,
      method: 'POST',
    })
    return response.data
  } catch (e) {
    return false
  }
}

export const fetchCentraData = async (command: string) => {
  let res = { data: null }
  try {
    res = await axios({
      url: `${API_BASE}${command}`,
    })
  } catch (error) {
    const e = new Error(error)
    e.log = `Error fetching Centra data: ${command}`
    throw e
  }
  return res.data
}
