import React from 'react'
import RichText from 'src/components/Modules/RichText'
import styles from './CookieConsent.module.scss'
import PrimaryButton from '../UI/Button/PrimaryButton';

type Props = {
  content: object
  removeCookie: () => void
}

class CookieConsent extends React.Component<Props> {

  state = {
    remove: false
  }

  handleClick = () => {
    this.setState({
      remove: true,
    })
    setTimeout(() => {
      if (this.props.removeCookie) {
        this.props.removeCookie()
      }
    }, 500)
  }

  render() {
    const { content } = this.props
    const { remove } = this.state

    return (
      <div className={remove ? [styles.cookieWrapper, styles.remove].join(' ') : styles.cookieWrapper}>
        <div className={styles.content + ' container'}>
          <RichText content={content} />
          <PrimaryButton className={styles.button} onClick={this.handleClick}>Accept</PrimaryButton>
        </div>
      </div>
    )
  }
}

export default CookieConsent