import * as React from 'react'

import styles from './PrimaryButton.module.scss'

type ButtonProps = {
  children: any
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  disableClick?: boolean
}

const PrimaryButton = ({
  children,
  onClick,
  disabled,
  disableClick
}: ButtonProps): JSX.Element => (
  <button
    className={disabled ? styles.disabledPrimaryButton : styles.primaryButton}
    onClick={onClick}
    disabled={disableClick}
  >
    {children}
  </button>
)

export default PrimaryButton
