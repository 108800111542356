import * as React from 'react'
import { Link } from 'gatsby'
import { inject, observer } from 'mobx-react'
import CartButton from 'src/components/Cart/CartButton'

import Image from 'src/components/UI/Image/Image'
import MenuButton from 'src/components/Menu/MenuButton'
import PreviewBar from 'src/components/PreviewBar/PreviewBar'
import isNode from 'is-node'
import styles from './Header.module.scss'

type Props = {
  config: any
  ui?: any
  preview?: any
}

// Logo should come from cms
@inject('ui')
@inject('preview')
@observer
class Header extends React.Component<Props> {
  wrapperRef: React.RefObject<HTMLDivElement>

  constructor(props: Props) {
    super(props)
    this.wrapperRef = React.createRef()
  }

  handleClick = () => {
    const { ui } = this.props
    ui.setActiveMenu('')
  }

  componentDidMount() {
    const { ui } = this.props
    if (this.wrapperRef && this.wrapperRef.current && ui) {
      ui.setHeaderHeight(this.wrapperRef.current.clientHeight)
    }
  }

  componentDidUpdate() {
    const { ui } = this.props
    if (this.wrapperRef && this.wrapperRef.current && ui) {
      if (ui.headerHeight !== this.wrapperRef.current.clientHeight) {
        ui.setHeaderHeight(this.wrapperRef.current.clientHeight)
      }
    }
  }

  render() {
    const { config, preview, ui } = this.props
    return (
      <header className={styles.headerWrapper + ' aino-header'} style={ui.headerHeight ? { height: ui.headerHeight } : {}}>
        <div ref={this.wrapperRef} className={styles.header}>
          {!isNode && preview && preview.isLoggedIn && <PreviewBar />}
          <div className="container">
            <div className={styles.wrapper}>
              <div className={styles.menu}>
                <MenuButton />
              </div>
              <Link className={styles.logo} to="/" onClick={this.handleClick}>
                {config.logotype ? (
                  <Image
                    src={config.logotype.fields.file.url}
                    alt={config.logotype.title}
                  />
                ) : (
                    <div>{config.logoText}</div>
                  )}
              </Link>
              <div className={styles.cart}>
                <CartButton />
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
