import * as React from 'react'

type Props = {
  color: string
  styles?: any
  width: number
  height?: number
}

function getHeight(width: number, ratio: number) {
  return Math.round(width * ratio)
}

export const EventIcon = ({
  color,
  styles,
  width,
  height,
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={styles}
    width={width}
    height={height || getHeight(width, 100 / 100)}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z"
    />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
)

export const SpinnerIcon = ({
  color,
  styles,
  width,
  height,
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={styles}
    width={width}
    height={height || getHeight(width, 100 / 100)}
    viewBox="0 0 38 38"
  >
    <defs>
      <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
        <stop stopColor={color} stopOpacity="0" offset="0%" />
        <stop stopColor={color} stopOpacity=".631" offset="63.146%" />
        <stop stopColor={color} offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)">
        <path
          d="M36 18c0-9.94-8.06-18-18-18"
          id="Oval-2"
          stroke="url(#a)"
          strokeWidth="2"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="0.9s"
            repeatCount="indefinite"
          />
        </path>
        <circle fill={color} cx="36" cy="18" r="1">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="0.9s"
            repeatCount="indefinite"
          />
        </circle>
      </g>
    </g>
  </svg>
)

export const PlusIcon = ({
  color,
  styles,
  width,
  height,
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={styles}
    width={width}
    height={height || getHeight(width, 100 / 100)}
    viewBox="0 0 24 24"
  >
    <path fill={color} d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
)

export const ArrowRightIcon = ({
  color,
  styles,
  width,
  height,
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={styles}
    width={width}
    height={height || getHeight(width, 100 / 100)}
    viewBox="0 0 24 24"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path fill={color} d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z" />
  </svg>
)

export const CloseIcon = ({
  color,
  styles,
  width,
  height,
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={styles}
    width={width}
    height={height || getHeight(width, 100 / 100)}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      strokeWidth="1px"
      stroke="white"
      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
    />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
)

export const CheckIcon = ({
  color,
  styles,
  width,
  height,
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={styles}
    width={width}
    height={height || getHeight(width, 100 / 100)}
    viewBox="0 0 32 32"
  >
    <path
      d="M9 16l5 5 9-9"
      fill="none"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
)

export const BasketIcon = ({
  color,
  styles,
  width,
  height,
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={styles}
    width={width}
    height={height || getHeight(width, 100 / 100)}
    viewBox="0 0 24 24"
  >
    <path
      strokeWidth="0.9px"
      fill="none"
      stroke={color}
      className="st0"
      d="M15.1 8H9M5.8 8H4.5c-.5 0-1 .5-1.1 1l-2 13.4c-.1.5.3 1 .8 1h19.6c.6 0 .9-.4.8-1L20.6 9c-.1-.5-.6-1-1.2-1H18"
    />
    <path
      strokeWidth="0.9px"
      d="M7.4 9.9V5.5S7 .6 12 .6s4.6 4.9 4.6 4.9v4.3"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeMiterlimit="10"
    />
  </svg>
)

export const PackageIcon = ({
  color,
  styles,
  width,
  height,
}: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={styles}
    width={width}
    height={height || getHeight(width, 100 / 100)}
    viewBox="0 0 24 24"
  >
    <path
      fill={color}
      d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
    />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
)

export const CartIcon = ({
  color,
  styles,
  width,
  height,
}: Props): JSX.Element => (
  <svg
    className={styles}
    width={width}
    height={height || getHeight(width, 100 / 100)}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path
        stroke={color}
        d="M.5 6.5h29v23H.5zM21 6.379C21 3.409 18.538 1 15.5 1S10 3.408 10 6.379"
      />
    </g>
  </svg>
)

export const CartIconHover = ({
  color,
  styles,
  width,
  height,
}: Props): JSX.Element => (
  <svg
    className={styles}
    width={width}
    height={height || getHeight(width, 100 / 100)}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path stroke="#000" fill={color} d="M.5 6.5h29v23H.5z" />
      <path
        d="M21 6.379C21 3.409 18.538 1 15.5 1S10 3.408 10 6.379"
        stroke={color}
      />
    </g>
  </svg>
)

export const ChevronDown = ({
  color,
  styles,
  width,
  height,
}: Props): JSX.Element => (
  <svg
    className={styles}
    width={width}
    height={height || getHeight(width, 70 / 130)}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 .657l5.657 5.657L12.314.657"
      stroke={color}
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)
