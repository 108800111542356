import * as React from 'react'

import styles from './InfoBar.module.scss'

interface Props {
  text: string
}

const InfoBar = ({ text }: Props) => (
  <div className={styles.wrapper + ' aino-infobar'}>
    <div>{text}</div>
  </div>
)

export default InfoBar
