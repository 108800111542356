const axios = require('axios')

const isPlainObject = obj =>
  Object.prototype.toString.call(obj) === '[object Object]'
const objectToArray = (obj, propName) => {
  if (isPlainObject(obj)) {
    return Object.keys(obj).map(key => {
      const nextObj = obj[key]
      if (isPlainObject(nextObj)) {
        return propName ? Object.assign(nextObj, { [propName]: key }) : nextObj
      }
      return nextObj
    })
  }
  return obj
}

const parseItems = items => {
  return Object.keys(items).map(key => {
    const item = items[key]
    if (item.stockByMarket) {
      item.stock = Object.keys(item.stockByMarket).map(market => ({
        market,
        amount: item.stockByMarket[market],
      }))
    }
    return item
  })
}

const parseMarkets = markets =>
  Object.keys(markets).map(market => {
    const nextMarket = markets[market]
    if (nextMarket.pricesByPricelist) {
      nextMarket.pricesByPricelist = objectToArray(
        nextMarket.pricesByPricelist,
        'pricelist'
      )
    }
    return Object.assign(nextMarket, { market })
  })

const parseProducts = products => {
  products = Object.keys(products).map(key => {
    const product = products[key]
    return {
      ...product,
      markets: parseMarkets(product.markets),
      items: parseItems(product.items),
    }
  })
  return products
}

const fetchCentraData = async (config, command) => {
  let res = { data: null }
  try {
    res = await axios({
      url: `${config.centra.url}${command}`,
      headers: {
        'API-Authorization': config.centra.auth,
      },
    })
  } catch (error) {
    const e = new Error(error)
    e.log = `Error fetching Centra data: ${command}`
    throw e
  }
  return res.data
}

const getCentraCategories = categoryData => {
  let categoryArray = []
  let categoryNumbers = []
  function deepCategoryIterator(target) {
    if (typeof target === 'object') {
      for (const key in target) {
        // Push category to categoryArray if object is category and not exists
        if (
          (target.inCategory || target.categories) &&
          !categoryNumbers.includes(target.category)
        ) {
          categoryNumbers.push(target.category)
          categoryArray.push(target)
        }
        deepCategoryIterator(target[key])
      }
    }
  }
  deepCategoryIterator(categoryData)
  return categoryArray
}

const getCentraImages = productsData => {
  const images = []
  if (productsData) {
    Object.keys(productsData).forEach(key => {
      if (productsData[key] && productsData[key].media) {
        productsData[key].media.forEach(image => {
          if (image.type === 'image') {
            images.push(image.sources.full.url)
          }
        })
      }
    })
  }
  return images
}

module.exports = {
  parseItems,
  parseMarkets,
  parseProducts,
  fetchCentraData,
  getCentraCategories,
  getCentraImages,
}
