import config from 'src/config'

const locales = config.locales
const localeCount = Object.keys(config.locales).length

export const getSlugByLocale = (slug, locale) => {
  if (slug && slug.startsWith('/')) {
    slug = slug.slice(1)
  }
  if (localeCount > 1) {
    const prefix = locales[locale] ? locales[locale].path : ''
    return `${prefix}/${slug}`
  } else {
    return `/${slug}`
  }
}
