export const envs = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
}

export const storageKeys = {
  CART: 'cart',
  PREVIEW: 'preview',
  COOKIE_ACCEPTED: 'cookie-accepted',
}

export const errorCodes = {
  ERROR: 'error',
}
