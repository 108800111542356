import * as React from 'react'
import { Link } from 'gatsby'

import Image from 'src/components/UI/Image/Image'
import Price from 'src/components/Product/Price/Price'
import { CloseIcon } from 'src/components/UI/Icon/Icon'
import { getPrice } from 'src/utils/product'

import ItemMissing from './ItemMissing'
import styles from './CartItem.module.scss'
import { inject, observer } from 'mobx-react'
import Ui from 'src/stores/ui'
import LinkComponent from '../UI/LinkComponent/LinkComponent'

type Props = {
  item: any
  cart: any
  handleClick?: () => void
  ui?: Ui
  locale: string
}

@inject('ui')
@observer
class CartItem extends React.Component<Props> {
  handleQuantityChange = async (quantity: number) => {
    try {
      const { cart, item } = this.props
      await cart.updateQuantity(item.line, quantity)
    } catch (error) {
      const { ui } = this.props
      console.log('Error when updating quantity')
    }
  }

  render() {
    const { item, cart, handleClick, locale } = this.props

    const productId = item.product.product
    const product = item.product

    if (!product) {
      // This could happen if customer has a removed item left in their cart
      // TODO Styling etc
      return <ItemMissing productId={productId} />
    }
    const { quantity, priceEach, priceEachAsNumber, priceEachBeforeDiscount, priceEachBeforeDiscountAsNumber } = item
    const { name, variantName, canonicalUri, categoryUri, uri } = product
    const image = product.media
    const canonical = cart.canonicals && cart.canonicals[item.sku] || categoryUri

    const fullName = item.size ? `${name} (${item.size})` : name

    return (
      <div className={styles.cartItem}>
        <div className={styles.image}>
          <Image ratio={4 / 3.2} src={image.full[0]} alt={name} />
        </div>
        <div className={styles.info}>
          <div>
            <LinkComponent to={`/${canonical}`} locale={locale} onClick={handleClick}>
              <span className={styles.name}>{fullName}</span>
            </LinkComponent>
            {variantName && <p className={styles.description}>{variantName}</p>}
          </div>
          <div className={styles.price}>
            {quantity} x{' '}
            <Price noStyle price={{
              price: priceEach,
              priceReductionAsNumber: priceEachBeforeDiscountAsNumber - priceEachAsNumber,
              priceBeforeDiscount: priceEachBeforeDiscount,
            }} />
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.plusminus}>
            <button
              onClick={() => this.handleQuantityChange(item.quantity - 1)}
              className={styles.button}
            >
              <span>-</span>
            </button>
            <button
              onClick={() => this.handleQuantityChange(item.quantity + 1)}
              className={styles.button}
            >
              <span>+</span>
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default CartItem
