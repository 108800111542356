import * as React from 'react'
import { inject, observer } from 'mobx-react'

import styles from './MenuButton.module.scss'

type Props = {
  ui?: any
}

@inject('ui')
@observer
class MenuButton extends React.Component<Props> {
  handleClick = () => {
    const { ui } = this.props
    ui.setActiveMenu(ui.activeMenu === 'menu' ? '' : 'menu')
  }

  render() {
    const { ui } = this.props
    const active = ui.activeMenu === 'menu'
    const menuClass = active
      ? [styles.menuButton, styles.menuButtonActive].join(' ')
      : styles.menuButton

    return (
      <div onClick={this.handleClick} className={menuClass}>
        <div className={styles.menuLine} />
        <div className={styles.menuLine} />
        <div className={styles.menuLine} />
      </div>
    )
  }
}

export default MenuButton
