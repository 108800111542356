import isNode from 'is-node'
import localForage from 'localforage'
import { observable, action } from 'mobx'
import { storageKeys } from 'src/constants'

export default class Cookie {
  @observable isAccepted: boolean = true

  constructor() {
    if (!isNode) {
      localForage
        .getItem(storageKeys.COOKIE_ACCEPTED)
        .then(async (accepted: boolean) => {
          if (accepted) {
            this.setCookieAccepted()
          } else {
            this.setCookieUnAccepted()
          }
        })
    }
  }

  @action.bound
  setCookieUnAccepted = () => {
    this.isAccepted = false
  }

  @action.bound
  setCookieAccepted = () => {
    localForage.setItem(storageKeys.COOKIE_ACCEPTED, JSON.stringify(true))
    this.isAccepted = true
  }
}
