import isNode from 'is-node'
import localForage from 'localforage'
import { observable, action, computed, reaction, toJS } from 'mobx'
import axios from 'axios'
import {
  createCart,
  addItem,
  refreshCart,
  removeItem,
  updateItemQuantity,
  changeCountry,
  loginCentraUser,
  fetchCentraProduct,
} from 'src/utils/api'
import { storageKeys } from 'src/constants'
import { sleep } from 'src/utils/misc'
import { api } from 'src/config'
// import algoliasearch from 'algoliasearch'
const NODE_COUNTRY = 'US'

type CartType = null | {
  token: string
  location: {
    country: string
    market: number
  }
  selection: {
    items: any[]
  }
}

export default class Cart {
  @observable cart: CartType = null
  @observable pricelists: object | null = null
  @observable loading = false
  @observable canonicals: string[] = []

  constructor() {
    if (!isNode) {
      localForage.getItem(storageKeys.CART).then(async (cart: any) => {
        if (cart) {
          this.updateCart(JSON.parse(cart))
          this.updateCart(await refreshCart(this.token))
        } else {
          this.updateCart(await createCart())
        }
      })

      // Fetching pricelists to always show newest prices from centra
      this.fetchPricelists()

      reaction(
        () => this.cart,
        (nextCart: CartType) => {
          // this.fetchCartItemCanonicals()
          console.log('Updated cart: ', toJS(nextCart))
          localForage.setItem(storageKeys.CART, JSON.stringify(nextCart))
        }
      )
    }
  }

  async fetchPricelists() {
    try {
      const response = await axios(`${api}/centra/pricelists`)

      this.setPriceLists(response.data)
    } catch (error) {
      console.warn(error)
    }
  }

  // async fetchCartItemCanonicals() {
  //   try {
  //     const ids = this.cart?.selection.items.map(
  //       (item: any) => item.product.product
  //     )
  //     if (ids && ids.length > 0) {
  //       let filters = ''
  //       ids.forEach((id: string, index: number) => {
  //         filters = filters + `objectID:${id}`
  //         if (ids.length > index + 1) {
  //           filters = filters + ' OR '
  //         }
  //       })

  //       const client = algoliasearch(
  //         process.env.GATSBY_ALGOLIA_APP_ID,
  //         process.env.GATSBY_ALGOLIA_KEY
  //       )
  //       const index = client.initIndex(process.env.GATSBY_ALGOLIA_INDEX)
  //       const response = await index.search('', { filters })
  //       this.setCanonicals(response.hits)
  //     }
  //   } catch (error) {
  //     console.warn(error)
  //   }
  // }

  async addItem(item: string) {
    this.setCartLoading()
    this.updateCart(await addItem(this.token, item))
  }

  async removeItem(item: string) {
    this.setCartLoading()
    this.updateCart(await removeItem(this.token, item))
  }

  async updateQuantity(line: string, quantity: string) {
    this.setCartLoading()
    this.updateCart(await updateItemQuantity(this.token, line, quantity))
  }

  @action.bound
  setPriceLists(pricelists: any) {
    this.pricelists = pricelists
  }

  @action.bound
  setCanonicals(items: any) {
    items.forEach((item: any) => {
      this.canonicals[item.sku] = item.canonicalUri
    })
  }

  @action.bound
  setCartLoading() {
    this.loading = true
  }

  @action.bound
  updateCart(cart: CartType) {
    this.loading = false
    this.cart = cart
  }

  @action.bound
  async setCountry(country: string) {
    this.updateCart(await changeCountry(this.token, country))
  }

  @action.bound
  async clear() {
    // TODO: do this correctly
    this.updateCart(await createCart())
  }

  @action.bound
  async loginUser(email: string, password: string) {
    const response = await loginCentraUser(this.token, email, password)
    if (response) {
      this.updateCart(response)
    } else {
      return false
    }
  }

  @computed
  get count(): number {
    if (!this.cart) {
      return 0
    }
    return this.cart.selection.items
      .map((item: any) => item.quantity)
      .reduce((a, b) => a + b, 0)
  }

  @computed
  get items(): object[] {
    if (!this.cart) {
      return []
    }
    return this.cart.selection.items
  }

  @computed
  get token(): string {
    return this.cart ? this.cart.token : ''
  }

  @computed
  get country(): string | null {
    if (isNode) {
      return NODE_COUNTRY
    }
    return this.cart ? this.cart.location.country : null
  }

  @computed
  get market(): string | null {
    if (isNode) {
      return null
    }
    return this.cart ? this.cart.location.market.toString() : null
  }

  getSelectedCountry = (countries: any): object | null => {
    if (!countries) return null
    if (this.cart && this.cart.location.country) {
      const filteredCountries = countries
        .filter(
          (country: any) => country.country === this.cart.location.country
        )
        .map((country: any) => ({
          value: country.country,
          label: country.name,
        }))
      if (filteredCountries && filteredCountries.length > 0) {
        return filteredCountries[0]
      }
    }
    return null
  }

  getAllCountries = (countries: any): object[] | null => {
    if (!countries) return null
    const response = countries
      .map((country: any) => ({
        value: country.country,
        label: country.name,
      }))
    return response
  }

  @action.bound
  async fetchCentraProduct(
    productId: string,
    counter = 0
  ): Promise<void | null> {
    // Make sure we have a token before we fetch product.
    // Otherwise we might get a product with the wrong price/availability
    // for the customer’s market.
    // TODO: We still need to handle timeouts
    const localProductId = parseInt(productId, 10)
    let localCounter = counter
    const token = this.token
    if (token && localProductId) {
      return await fetchCentraProduct(token, localProductId)
    } else {
      localCounter++
      if (localCounter > 6) {
        // Should we try longer? Harder? Scooter?
        return null
      }
      await sleep(50 * localCounter)
      return await this.fetchCentraProduct(productId, localCounter)
    }
  }

  async refresh() {
    this.updateCart(await refreshCart(this.token))
  }
}
