import * as React from 'react'
import { inject, observer } from 'mobx-react'

import styles from './PreviewBar.module.scss'

type Props = {
  preview?: any
}

@inject('preview')
@observer
class PreviewBar extends React.Component<Props> {
  handleChange = (e: any) => {
    const { preview } = this.props
    preview.setPreviewStatus(e.target.checked)
  }
  logoutUser = () => {
    const { preview } = this.props
    preview.logoutUser()
  }

  render() {
    const { preview } = this.props

    return (
      <div className={styles.previewBar}>
        <div className={styles.previewWrapper}>
          <div className={styles.logout} />
          <div className={styles.switchContainer}>
            <span className={styles.text}>Stable</span>
            <label className={styles.switcher}>
              <input type="checkbox" onChange={this.handleChange} />
              <span className={styles.slider} />
            </label>
            <span
              className={styles.text}
              style={{
                opacity: preview.previewActive ? 1 : 0.3,
                color: preview.previewActive ? 'rgb(9, 207, 148)' : '#fff',
              }}
            >
              Preview
            </span>
          </div>
          <div className={styles.logout}>
            <button className={styles.logoutButton} onClick={this.logoutUser}>
              Log out
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default PreviewBar
