export function camelCase(string = '') {
  if (string.includes(' ')) {
    let result = string.toLowerCase()
    result = result.replace(/[_\W]/g, ' ')
    return result.replace(/\s+(.)/g, function(m, $1) {
      return $1.toUpperCase()
    })
  }
  return string
}

export function getShortISODate(date: Date) {
  let month = '' + (date.getMonth() + 1)
  let day = '' + date.getDate()
  const year = date.getFullYear()

  if (month.length < 2) {
    month = '0' + month
  }

  if (day.length < 2) {
    day = '0' + day
  }

  return [year, month, day].join('-')
}

export async function sleep(ms: number) {
  // TODO: Promise should be polyfilled by Gatsby, but check
  // IE11 just in case due to some reported issues.
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}
