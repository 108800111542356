import * as React from 'react'
import Input from 'src/components/UI/Input/Input'
import PrimaryButton from 'src/components/UI/Button/PrimaryButton'

import styles from './Newsletter.module.scss'
import { SpinnerIcon, CheckIcon } from '../UI/Icon/Icon'
import { signupNewsletter } from 'src/utils/api'

interface Props {
  title: string
  text: string
  apiKey: string
  site: string
  tag: string
}

class Newsletter extends React.Component<Props> {

  state = {
    email: '',
    valid: false,
    loading: false,
    success: false,
    showRequired: false
  }

  validateEmail = (email: string) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleChange = (e: any) => {
    this.setState({
      email: e.target.value,
      valid: this.validateEmail(e.target.value),
      showRequired: false
    })
  }

  handleSubmit = async (e: any) => {
    e.preventDefault()
    e.stopPropagation()

    const { valid, loading } = this.state

    if(valid && !loading){          
      this.setState({
        loading: true
      })

      try {
        const response = await signupNewsletter(this.state.email)
        if (response) {
          this.setState({ loading: false, success: true })
          setTimeout(() => {
            this.setState({ success: false })

          }, 5000)
          return response.data
        } else {
          this.setState({
            loading: false
          })
        }
      } catch (e) {
        this.setState({ loading: false })
      }
    } else if(!valid) {
      this.setState({
        showRequired: true
      })
    }
  }

  render() {
    const { title, text } = this.props
    const { loading, success, showRequired } = this.state
    return (
      <div className={styles.newsletterWrapper}>
        <h4>{title}</h4>
        <p>{text}</p>
        <form className={styles.newsletterForm} onSubmit={this.handleSubmit}>
          <Input onChange={this.handleChange} type="email" name="email" placeholder="Email" />
          <PrimaryButton>
            {loading ? (
              <SpinnerIcon height={16} width={16} color="white" />
            ) : success ? (
              <CheckIcon
                styles={styles.checkmark}
                height={36}
                width={36}
                color="white"
              />
            ) : (
                  'Sign up'
                )}
          </PrimaryButton>
        </form>
        {showRequired && (
          <p className={styles.required}>{'Use a valid email address'}</p>
        )}
      </div>
    )
  }
}

export default Newsletter
