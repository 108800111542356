const contentful = require('contentful')

const isPrimarySite = (config, site) => {
  const siteName = config.siteName
    .toLowerCase()
    .split(' ')
    .join('_')
  return (
    site
      .toLowerCase()
      .split(' ')
      .join('_') === siteName
  )
}

const isPageForCurrentSite = (config, site) => {
  const siteName = config.siteName
    .toLowerCase()
    .split(' ')
    .join('_')

  const globalName = config.globalSiteName
    .toLowerCase()
    .split(' ')
    .join('_')

  site = site
    .toLowerCase()
    .split(' ')
    .join('_')

  return site === globalName || site === siteName
}

const getContentTypeArray = (items, type) => {
  return items
    .filter(item => item.sys.contentType.sys.id === type)
    .map(item => {
      return {
        ...item.fields,
        contentType: item.sys.contentType.sys.id,
        locale: item.sys.locale,
      }
    })
}

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}

const getLocalePath = (config, slug, locale) => {
  const localeCount = Object.keys(config.locales).length
  if (slug.startsWith('/')) {
    slug = slug.slice(1)
  }
  if (localeCount > 1) {
    return `${config.locales[locale].path}/${slug}`
  } else {
    return `/${slug}`
  }
}

const fetchContentful = async config => {
  try {
    const client = contentful.createClient({
      space: config.contentful.space,
      accessToken: config.contentful.accessToken,
      removeUnresolved: true,
    })

    let entries = []
    await asyncForEach(Object.keys(config.locales), async locale => {
      const response = await client.getEntries({ locale: locale })
      entries = [...entries, ...response.items.filter(item => item.fields)]
    })
    return entries
  } catch (error) {
    const e = new Error(error)
    e.log = 'Error fetching Contentful data'
    throw e
  }
}

const fetchContentfulImages = async config => {
  try {
    const client = contentful.createClient({
      space: config.contentful.space,
      accessToken: config.contentful.accessToken,
    })
    const response = await client.getAssets()
    const images = response.items
      .filter(item => {
        const file = item.fields.file
        if (file && file.contentType.startsWith('image')) {
          return true
        }
        return false
      })
      .map(item => {
        const file = item.fields.file
        if (file) {
          return file.url
        }
      })
    return images
  } catch (error) {
    const e = new Error(error)
    e.log = 'Error fetching Contenful images'
    throw e
  }
}

const shouldCreatePage = (config, page, createdPages) => {
  if (
    !createdPages.includes(getLocalePath(config, page.slug, page.locale)) &&
    isPageForCurrentSite(config, page.site)
  ) {
    return true
  } else if (isPrimarySite(config, page.site)) {
    return true
  }
  return false
}

module.exports = {
  fetchContentful,
  fetchContentfulImages,
  getLocalePath,
  getContentTypeArray,
  shouldCreatePage,
}
