import * as React from 'react'
import Helmet from 'react-helmet'

import l from 'src/assets/images/favicon-192x192.png'
import m from 'src/assets/images/favicon-32x32.png'
import s from 'src/assets/images/favicon-16x16.png'

import config from 'src/config'

import isNode from 'is-node'

type Props = {
  bodyClass?: string
  canonical?: string
  children?: React.ReactChild | React.ReactChildren
  lang?: string
  noIndex?: boolean
  seoMetaDescription?: string
  seoOgimage?: string
  seoTitle?: string
  title?: string
  type?: string
}

const { locales } = config

const SEO = ({
  bodyClass,
  canonical,
  children,
  noIndex = false,
  seoMetaDescription,
  seoOgimage,
  seoTitle,
  title,
  type,
}: Props): JSX.Element => {

  let currentBodyClass = ''

  if (!isNode && document && document.body.classList.value.includes('support-webp')) {
    currentBodyClass = 'support-webp'
  }

  const props = {
    bodyAttributes: {
      class: bodyClass ? `${bodyClass} ${currentBodyClass}` : currentBodyClass,
    },
    title: title || seoTitle || '',
  }
  
  if (seoOgimage && seoOgimage.startsWith('//')) {
    seoOgimage = `https:${seoOgimage}`
  }
  
  return (
    <Helmet defer={false} {...props}>
      {canonical &&
        Object.keys(locales).map(locale => (
          <link
            href={`${locales[locale].path}${canonical}`}
            hrefLang={locale}
            key={`alternate_link_${locale}`}
            rel="alternate"
          />
        ))}

      <link rel="icon" type="image/png" sizes="192x192" href={l} />
      <link rel="icon" type="image/png" sizes="32x32" href={m} />
      <link rel="icon" type="image/png" sizes="16x16" href={s} />

      {noIndex && <meta name="robots" content="noindex, nofollow" />}

      {seoTitle && <meta property="og:title" content={seoTitle} />}
      {seoTitle && <meta property="twitter:title" content={seoTitle} />}
      {seoTitle && <meta property="og:image:alt" content={seoTitle} />}
      {seoTitle && <meta property="twitter:image:alt" content={seoTitle} />}

      {seoMetaDescription && (
        <meta name="description" content={seoMetaDescription} />
      )}
      {seoMetaDescription && (
        <meta name="og:description" content={seoMetaDescription} />
      )}

      {seoOgimage && <meta property="og:image" content={seoOgimage} />}
      {seoOgimage && <meta property="twitter:image:src" content={seoOgimage} />}

      {type && <meta property="og:type" content={type} />}
      {type && <meta property="twitter:type" content={type} />}

      {children}
    </Helmet>
  )
}

export default SEO
