import * as React from 'react'

import styles from './Input.module.scss'

interface Props {
  placeholder?: string
  onChange?: (e: any) => void
  type: string
  name: string
}

const Input = ({ placeholder, name, type, onChange }: Props) => (
  <input
    onChange={onChange}
    type={type}
    name={name}
    className={styles.input}
    placeholder={placeholder}
  />
)

export default Input
