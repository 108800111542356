import * as React from 'react'
import t from 'typy'
import { inject, observer } from 'mobx-react'
import { Link } from 'gatsby'

import Image from 'src/components/UI/Image/Image'
import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'
import Newsletter from 'src/components/Modules/Newsletter'

import styles from './Footer.module.scss'

type Props = {
  config: any
  cart?: any
}

@inject('cart')
@observer
class Footer extends React.Component<Props> {
  handleCountryChange = (e: any) => {
    const { cart } = this.props
    e.preventDefault()
    e.stopPropagation()
    if (cart && e.target.value) {
      cart.setCountry(e.target.value)
    }
  }

  render() {
    const { config, cart } = this.props
    const links = t(config, 'footerLinks').safeObject
    const newsletter = t(config, 'newsletter.fields').safeObject
    const footerCopyrightText = t(config, 'footerCopyrightText').safeObject
    const operatedByLink = t(config, 'operatedByLink').safeObject
    const paymentLogotypes = t(config, 'paymentLogotypes').safeObject
    const countries = t(cart, 'cart.countries').safeObject
    const selectedCountry = cart.getSelectedCountry(countries)
    const allCountries = cart.getAllCountries(countries)
        
    return (
      <footer className={styles + ' aino-footer'}>
        <section className={styles.upperSection}>
          <div className="container">
            <div className={styles.upperFlex}>
              <ul>
                {links &&
                  links.map((link: any, index: number) => (
                    <li key={index}>
                      <LinkComponent
                        locale={t(link, 'sys.locale').safeObject}
                        to={t(link, 'fields.slug').safeObject}
                      >
                        {t(link, 'fields.title').safeObject}
                      </LinkComponent>
                    </li>
                  ))}
                <li>
                  <Link to="customer">Log in</Link>
                </li>
              </ul>
              {newsletter && <Newsletter {...newsletter} site={config.site} apiKey={config.ruleApiKey} tag={config.ruleTag} />}
            </div>
            <div className={styles.shipToSection}>
              <div className={styles.countries}>
                {selectedCountry && (
                  <span>
                    Ship to:{' '}
                    <select
                      onChange={this.handleCountryChange}
                      className={styles.footerSelect}
                      defaultValue={selectedCountry.value}
                    >
                      {allCountries.map((item: any, index: number) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </span>
                )}
              </div>
              {paymentLogotypes && (
                <div className={styles.logos}>
                  {paymentLogotypes.map((logo: any, index: number) => (
                    <Image
                      key={index}
                      src={logo.fields.file.url}
                      alt={logo.fields.title}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </section>
        <section className={styles.lowerSection}>
          <div className="container">
            <div className={styles.lowerWrapper}>
              <span>{footerCopyrightText}</span>
              <span>Operated by <LinkComponent to={operatedByLink}>ACOS</LinkComponent></span>
            </div>
          </div>
        </section>
      </footer>
    )
  }
}

export default Footer
