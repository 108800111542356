import t from 'typy'

export const getSeoData = data => {
  if (!data) {
    return {}
  }
  let { seoMetaDescription, seoTitle, seoOgimage } = data.fields
  if (seoOgimage) {
    seoOgimage = t(seoOgimage, 'fields.file.url').safeObject
  }

  return { seoMetaDescription, seoTitle, seoOgimage }
}
