import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { Link } from 'gatsby'
import t from 'typy'
import posed, { PoseGroup } from 'react-pose'

import LinkComponent from 'src/components/UI/LinkComponent/LinkComponent'

import styles from './Menu.module.scss'

type Props = {
  ui?: any
  menu: any
}

const Item = posed.div({
  open: {
    y: -20,
    opacity: 1,
    delay: 300,
    transition: { duration: 150 },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: { duration: 150 },
  },
})

@inject('ui')
@observer
class Menu extends React.Component<Props> {
  contentRef: React.RefObject<HTMLDivElement>
  wrapperRef: React.RefObject<HTMLDivElement>

  constructor(props: Props) {
    super(props)
    this.wrapperRef = React.createRef()
    this.contentRef = React.createRef()
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside = (event: any) => {
    if (
      this.wrapperRef &&
      this.wrapperRef.current &&
      this.wrapperRef.current.contains(event.target) &&
      this.contentRef &&
      this.contentRef.current &&
      !this.contentRef.current.contains(event.target)
    ) {
      this.closeMenu()
    }
  }

  closeMenu = () => {
    const { ui } = this.props
    ui.setActiveMenu('')
  }

  render() {
    const { ui, menu } = this.props
    const active = ui.activeMenu === 'menu'

    const itemClass = active
      ? [styles.menuItem, styles.menuItemActive].join(' ')
      : styles.menuItem

    const menuClass = active
      ? [styles.menuOverlay, styles.menuOverlayActive].join(' ')
      : styles.menuOverlay

    return (
      <div
        ref={this.wrapperRef}
        style={ui.headerHeight ? { top: ui.headerHeight } : {}}
        className={menuClass}
      >
        <div ref={this.contentRef} className={styles.menuContent}>
          <PoseGroup pose={active ? 'open' : 'closed'}>
            {menu.links && menu.links.map((link: any, index: number) => (
              <Item key={index}>
                <LinkComponent
                  className={itemClass}
                  onClick={this.closeMenu}
                  to={t(link, 'fields.slug').safeObject}
                  locale={t(link, 'sys.locale').safeObject}
                >
                  {t(link, 'fields.title').safeObject}
                </LinkComponent>
              </Item>
            ))}
          </PoseGroup>
        </div>
      </div>
    )
  }
}

export default Menu
