import * as React from 'react'

import { getPrice } from 'src/utils/product'

import styles from './Price.module.scss'

type PriceType = {
  price: ReturnType<typeof getPrice>
  noStyle?: boolean
}

/*
 * Price can be null if the cart has not loaded yet
 * or if the product is unavailable
 */
const Price = ({ price, noStyle }: PriceType): JSX.Element => {
  const isSale = price && price.priceReductionAsNumber && price.priceReductionAsNumber > 0 ? true : false
  return noStyle ? (
    <React.Fragment>
      <span className={isSale ? 'sale price' : 'price'}>{price ? price.price : ''}</span>
      {isSale && (
        <span className={'old'}>{price ? price.priceBeforeDiscount : ''}</span>
      )}
    </React.Fragment>
  ) : (
      <div className={styles.priceWrapper}>
        <div className={isSale ? [styles.price, styles.salePrice].join(' ') : styles.price}>{price ? price.price : ''}</div>
        {isSale && (
          <div className={[styles.price, styles.oldPrice].join(' ')}>{price ? price.priceBeforeDiscount : ''}</div>
        )}
      </div>
    )
}

export default Price
