// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-tsx": () => import("/home/salonen/sites/ensemble.build/app/projects/acos-5ced39306c98236cc957eefb/src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-category-tsx": () => import("/home/salonen/sites/ensemble.build/app/projects/acos-5ced39306c98236cc957eefb/src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-information-tsx": () => import("/home/salonen/sites/ensemble.build/app/projects/acos-5ced39306c98236cc957eefb/src/templates/information.tsx" /* webpackChunkName: "component---src-templates-information-tsx" */),
  "component---src-templates-faq-tsx": () => import("/home/salonen/sites/ensemble.build/app/projects/acos-5ced39306c98236cc957eefb/src/templates/faq.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-templates-checkout-tsx": () => import("/home/salonen/sites/ensemble.build/app/projects/acos-5ced39306c98236cc957eefb/src/templates/checkout.tsx" /* webpackChunkName: "component---src-templates-checkout-tsx" */),
  "component---src-templates-receipt-tsx": () => import("/home/salonen/sites/ensemble.build/app/projects/acos-5ced39306c98236cc957eefb/src/templates/receipt.tsx" /* webpackChunkName: "component---src-templates-receipt-tsx" */),
  "component---src-pages-404-tsx": () => import("/home/salonen/sites/ensemble.build/app/projects/acos-5ced39306c98236cc957eefb/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-customer-tsx": () => import("/home/salonen/sites/ensemble.build/app/projects/acos-5ced39306c98236cc957eefb/src/pages/customer.tsx" /* webpackChunkName: "component---src-pages-customer-tsx" */),
  "component---src-pages-index-tsx": () => import("/home/salonen/sites/ensemble.build/app/projects/acos-5ced39306c98236cc957eefb/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

