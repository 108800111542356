import t from 'typy'

import config from 'src/config'

const siteName = config.siteName
  .toLowerCase()
  .split(' ')
  .join('_')

const globalName = config.globalSiteName
  .toLowerCase()
  .split(' ')
  .join('_')

export const getCurrentSiteData = data => {
  let filtered = data.filter(
    item =>
      t(item, 'site')
        .safeObject.toLowerCase()
        .split(' ')
        .join('_') === siteName
  )
  if (filtered && filtered.length > 0) {
    return filtered[0]
  } else {
    filtered = data.filter(
      item =>
        t(item, 'site')
          .safeObject.toLowerCase()
          .split(' ')
          .join('_') === globalName
    )
    return filtered[0]
  }
}
