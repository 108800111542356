import React from 'react'

import Footer from 'src/components/Footer/Footer'
import Header from 'src/components/Header/Header'
import Menu from 'src/components/Menu/Menu'

import configs from 'src/json/siteconfigs.json'
import menus from 'src/json/menus.json'

import { getCurrentSiteData } from 'src/helpers/getCurrentSiteData'

import InfoBar from './InfoBar/InfoBar'
import Preview from 'src/stores/preview'
import { inject, observer } from 'mobx-react'
import CookieConsent from './CookieConsent/CookieConsent'
import Cookie from 'src/stores/cookie'
import CheckoutHeader from './CheckoutHeader/CheckoutHeader'

import isNode from 'is-node'
import Cart from './Cart/Cart'

const config = require('src/config')

type Props = {
  children: any
  location?: any
  pageContext: any
  preview: Preview
  cookie: Cookie
}

@inject('preview', 'cookie')
@observer
class Layout extends React.Component<Props> {
  render() {
    const { children, preview, cookie } = this.props

    let config = getCurrentSiteData(configs)
    let menu = getCurrentSiteData(menus)

    if (preview && preview.previewData) {
      const previewConfig = preview.getCurrentPageContext(
        config.contentType,
        config.locale
      )
      const previewMenu = preview.getCurrentPageContext(
        menu.contentType,
        menu.locale
      )

      if (previewConfig) {
        config = previewConfig
      }
      if (previewMenu) {
        menu = previewMenu
      }
    }

    const { infoBarMessage } = config

    return (
      <React.Fragment>
        <Header config={config} />
        <Cart locale={config.locale} />
        {infoBarMessage && <InfoBar text={infoBarMessage} />}
        <Menu menu={menu} />
        {children}
        <Footer config={config} />
        {cookie && !cookie.isAccepted && config.cookieConsentText && !isNode && (
          <CookieConsent content={config.cookieConsentText} removeCookie={cookie.setCookieAccepted} />
        )}
      </React.Fragment>
    )
  }
}


export default Layout
